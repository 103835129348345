import React from "react"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import { css } from "emotion"
import moment from "moment"
import NewsPhoto from "../images/news-photo.jpg"
import CalendarPanel from "../components/CalendarPanel"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "react-big-calendar/lib/css/react-big-calendar.css"
import {
  PageHeaderText
} from "../components/common"

const TitleImage = styled(Grid)`
  height: 35vh;
  width: 100%;
  background: linear-gradient(
      180deg,
      rgba(10, 10, 10, 0.61) 35.42%,
      rgba(51, 121, 200, 0.85) 99.48%
    ),
    url(${NewsPhoto});
  background-size: cover;
  display: flex;
`
const CalendarContainer = styled(Grid)`
  font-size: 12px;
  color: black;
`

export default ({ data }) => {
  const eventData = data ? data.allGoogleCalendarEvent.nodes : []
  const today = moment()

  return (
    <Layout>
      <SEO title="Calendar" />
      <Grid container>
        <TitleImage item xs={12}>
          <PageHeaderText>Calendar</PageHeaderText>
        </TitleImage>
        <CalendarContainer item xs={12}>
          <CalendarPanel
            className={css`
              height: 60vh;
            `}
            eventData={eventData}
            min={today
              .set("hour", 7)
              .set("minute", 0)
              .toDate()}
            max={today
              .set("hour", 23)
              .set("minute", 0)
              .toDate()}
          />
        </CalendarContainer>
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query allGoogleEventsQuery {
    allGoogleCalendarEvent {
      nodes {
        location
        slug
        start {
          dateTime
        }
        summary
        status
        end {
          dateTime
        }
      }
    }
  }
`
