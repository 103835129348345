import React from "react"
import Grid from "@material-ui/core/Grid"
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment"

const localizer = momentLocalizer(moment)

const getEventData = event => {
  return {
    ...event,
    title: event.summary,
    start: moment(event.start.dateTime).toDate(),
    end: moment(event.end.dateTime).toDate(),
  }
}

const CalendarPanel = ({
  eventData,
  className,
  showToolbar,
  min,
  max,
  views,
  defaultView = "month",
  components
}) => {
  const events = eventData.map(event => getEventData(event))

  return (
    <Grid item xs={12}>
      <Calendar
        className={className}
        localizer={localizer}
        events={events || []}
        defaultView={defaultView}
        views={views}
        min={min}
        max={max}
        toolbar={showToolbar}
        components={components}
      />
    </Grid>
  )
}

export default CalendarPanel
